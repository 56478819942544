.master-container {
  padding-left: 5%;
  padding-right: 5%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}